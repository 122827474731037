import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
// import { MissionWithAssociationDto } from '../../dto/mission';
import routes from '../../api/routes';
import {
	Box,
	Button,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import Title from '../../components/Title';
import { Download } from '@mui/icons-material';
import { GridArrowDownwardIcon } from '@mui/x-data-grid';
import { Pagination } from '@material-ui/lab';
import moment from 'moment';
import { MissionExpensesDto } from '../../dto/missionExpenses';
import Modal from '../../components/Modal';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { CardContainer, Card } from '../../components/Cards';
import NumberInput from '../../components/form/NumberInput';
import SubmitButton from '../../components/form/SubmitButton';
import { post } from '../../api';
import DeleteButton from '../../components/form/DeleteButton';

const MissionAccounting = () => {
	const theme = useTheme();
	const isBigScreen = useMediaQuery(theme.breakpoints.up('md'));
	const [page, setPage] = useState(1);
	const { missionId = 0 } = useParams<{ missionId: string }>();
	// let [isMissionLoading, mission] = useFetch<MissionWithAssociationDto>(
	// 	routes.missions.get({ id: +missionId }),
	// );
	const [refresh, setRefresh] = useState(0);
	let [isMissionExpensesLoading, rawMissionExpenses] = useFetch<MissionExpensesDto[]>(
		routes.missions.getExpenses({ id: +missionId }),
		[window.location.pathname, refresh],
	);
	const [sort, setSort] = useState<{}>({ datetime: 'DESC' });
	const [missionExpenses, setMissionExpenses] = useState<MissionExpensesDto[]>([]);
	const [openExpense, setOpenExpense] = useState<MissionExpensesDto | null>(null);
	const isRot = window.location.pathname.includes('rot');

	const [label, setLabel] = useState('');
	const [amount, setAmount] = useState(0);
	const [tva, setTva] = useState(0);
	const [amountWithoutTva, setAmountWithoutTva] = useState(0);

	const columns = [
		{ label: 'Libellé', field: 'label' },
		{ label: 'Date', field: 'date' },
		{ label: 'Prix HT', field: 'amountWithoutTva' },
		{ label: 'TVA', field: 'tva' },
		{ label: 'Prix TTC', field: 'amount' },
		// { label: 'Recruteur', field: 'recruiterFirstName' },
	];

	const sortBy = (field: string) => () => {
		if (sort[field] === 'DESC') {
			setSort({ [field]: 'ASC' });
		} else if (sort[field] === 'ASC') {
			setSort({});
		} else {
			setSort({ [field]: 'DESC' });
		}
	};

	useEffect(() => {
		const sortField = Object.keys(sort)[0];
		const sortOrder = Object.values(sort)[0];

		const sortedMissionExpenses = [...missionExpenses].sort((a, b) => {
			const valueA = a[sortField];
			const valueB = b[sortField];

			if (sortOrder === 'ASC') {
				if (valueA < valueB) return -1;
				if (valueA > valueB) return 1;
				return 0;
			} else {
				// 'DESC'
				if (valueA > valueB) return -1;
				if (valueA < valueB) return 1;
				return 0;
			}
		});

		setMissionExpenses(sortedMissionExpenses);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sort]);

	useEffect(() => {
		setMissionExpenses(rawMissionExpenses);
	}, [rawMissionExpenses]);

	async function downloadFiles() {
		const zip = new JSZip();
		const folder = zip.folder('images');

		for (const expense of missionExpenses) {
			if (expense.fileUrl) {
				const response = await fetch(expense.fileUrl);
				if (!response.ok) {
					// handle any errors here
					console.error(`Failed to download file from ${expense.fileUrl}`);
					continue;
				}
				const blob = await response.blob();
				folder.file(expense.id.toString() + '_' + expense.label + '.jpeg', blob, { binary: true });
			}
		}

		// generate and download the zip file
		zip.generateAsync({ type: 'blob' }).then(function (content) {
			saveAs(content, 'tickets.zip');
		});
	}

	async function downloadFile() {
		if (openExpense.fileUrl) {
			const response = await fetch(openExpense.fileUrl);
			if (!response.ok) {
				// handle any errors here
				console.error(`Failed to download file from ${openExpense.fileUrl}`);
				return;
			}
			const blob = await response.blob();
			// save the file with the openExpense's label as the name
			saveAs(blob, `${openExpense.label}.jpeg`);
		} else {
			console.error(`Expense with id ${openExpense.id} has no file url.`);
		}
	}

	if (isMissionExpensesLoading || !missionExpenses) return null;

	return (
		<Box p={3}>
			<Box mb={2} display="flex" justifyContent="space-between">
				<Title>Liste des dépenses</Title>
				<Box>
					{/* <Link
						href={routes.donations.excel(
							{ clientId: user && user.client ? user.client.id : '0' },
							{ filters: serialize({ ...filters, searchValue: inputSearch }) },
						)}
					> */}
					<Button size="small" variant="contained" color="primary" onClick={downloadFiles}>
						<Download />
					</Button>
					{/* </Link> */}
				</Box>
			</Box>

			<CardContainer>
				<Card title="Prix HT">
					{missionExpenses.reduce((total, expense) => total + (expense?.amountWithoutTva || 0), 0)}
				</Card>
				<Card title="Montant TVA">
					{missionExpenses.reduce((total, expense) => total + (expense?.tva || 0), 0)}
				</Card>
				<Card title="Prix TTC">
					{missionExpenses.reduce((total, expense) => total + (expense?.amount || 0), 0)}
				</Card>
			</CardContainer>

			{openExpense && (
				<Modal open onClose={() => setOpenExpense(null)}>
					<div style={{ marginBottom: 10 }}>
						<TextField
							variant="outlined"
							label="Libellé"
							style={{ marginRight: 10 }}
							value={label}
							onChange={(e) => setLabel(e.target.value)}
						/>
						<NumberInput
							variant="outlined"
							label="Prix HT"
							style={{ marginRight: 10 }}
							value={amountWithoutTva}
							onChange={setAmountWithoutTva}
							isFloat
						/>
						<NumberInput
							variant="outlined"
							label="TVA"
							style={{ marginRight: 10 }}
							value={tva}
							onChange={setTva}
							isFloat
						/>
						<NumberInput
							variant="outlined"
							label="Prix TTC"
							style={{ marginRight: 10 }}
							value={amount}
							onChange={setAmount}
							isFloat
						/>
					</div>
					<div style={{ marginBottom: 10, display: 'flex' }}>
						<Button onClick={() => setOpenExpense(null)}>Annuler</Button>
						<SubmitButton
							edit
							onClick={async () => {
								await post(routes.missions.editMissionExpense({ id: openExpense.id }), {
									tva,
									amountWithoutTva,
									amount,
									label,
								});
								setRefresh(1 + refresh);
								setOpenExpense(null);
							}}
						>
							Confirmer
						</SubmitButton>
						<div style={{ width: 10 }} />
						{openExpense?.fileUrl && (
							<Button size="small" variant="contained" color="primary" onClick={downloadFile}>
								<Download />
							</Button>
						)}
						<div style={{ width: 10 }} />
						<DeleteButton
							url={routes.missions.getExpenses({ id: openExpense.id })}
							onSuccess={() => {
								setRefresh(1 + refresh);
								setOpenExpense(null);
							}}
						/>
					</div>
					{(openExpense?.fileUrl ?? '').length === 0 && <p>Pas de photo</p>}
					<img src={openExpense?.fileUrl} alt="" />
				</Modal>
			)}

			<Box
				my={2}
				style={{
					overflow: 'auto',
					maxWidth: isBigScreen ? 'calc(100vw - 346px)' : 'calc(100vw - 20px)',
					border: '1px solid #CCC',
					borderRadius: '3px',
					marginTop: 15,
				}}
			>
				<Table size="medium">
					<TableHead>
						<TableRow>
							{columns.map((column, i) => (
								<TableCell style={{ cursor: 'pointer' }} key={i}>
									<Box
										display="flex"
										flexDirection="row"
										justifyContent="space-between"
										alignItems="center"
										onClick={sortBy(column.field)}
									>
										<Box>{column.label}</Box>
										{column.field ? (
											sort[column.field] && (
												<IconButton size="small" style={{ marginLeft: 10 }}>
													<GridArrowDownwardIcon
														style={{
															fontSize: 14,
															transition: 'transform 0.3s',
															transform:
																sort[column.field] === 'ASC'
																	? 'rotate(180deg)'
																	: 'rotate(0deg)',
														}}
														fontSize="small"
													/>
												</IconButton>
											)
										) : (
											<span>Prêt</span>
										)}
									</Box>
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{missionExpenses?.map((expense, i) => (
							<TableRow
								key={i}
								style={{ cursor: 'pointer' }}
								onClick={() => {
									setOpenExpense(expense);
									setAmount(expense?.amount);
									setAmountWithoutTva(expense?.amountWithoutTva);
									setTva(expense?.tva);
									setLabel(expense?.label);
								}}
							>
								<TableCell>
									{isRot ? expense.firstName + ' ' + expense.lastName + ': ' : ''}
									{expense.label}
								</TableCell>
								<TableCell>{expense.date ? moment(expense.date).format('DD/MM/YYYY') : ''}</TableCell>
								<TableCell>{expense.amountWithoutTva}</TableCell>
								<TableCell>{expense.tva}</TableCell>
								<TableCell>{expense.amount}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>

				<Box p={1} display="flex" justifyContent="end">
					<Pagination
						page={page}
						onChange={(_, page) => {
							setPage(page);
						}}
						// count={Math.ceil((currentCount?.count || 0) / rowsPerPage)}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default MissionAccounting;
